import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = { class: "trending-block" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "multi-block-group-title" };
const _hoisted_4 = {
    key: 1,
    class: "trending-block-subhead"
};
const _hoisted_5 = ["href"];
const _hoisted_6 = { class: "trending-block-row" };
const _hoisted_7 = ["src"];
const _hoisted_8 = ["data-ga-action", "data-ga4-action", "aria-live", "tabindex", "aria-label", "title", "onClick"];
const _hoisted_9 = { class: "trending-title" };
const _hoisted_10 = { key: 0 };
import { reactive } from 'vue';
import Modal from '@components/Core/Modal.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TrendingBlock',
    props: {
        t: {
            type: Object,
            default: () => ({
                title: '',
                subhead: '',
                subheadLinkText: ''
            })
        },
        trendings: {
            type: (Array),
            default: () => []
        },
        subheadLink: {
            type: String,
            default: ''
        }
    },
    setup(__props) {
        const props = __props;
        const trendingList = reactive(props.trendings.map((trending) => ({
            ...trending,
            show: false
        })));
        const onBlockClick = (trending) => {
            if (trending.url) {
                window.location.href = trending.url;
                return;
            }
            trending.show = true;
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", _hoisted_1, [
                (props.t.title)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("h3", _hoisted_3, _toDisplayString(props.t.title), 1)
                    ]))
                    : _createCommentVNode("", true),
                (props.t.subhead.length > 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                        _createTextVNode(_toDisplayString(props.t.subhead) + " ", 1),
                        _createElementVNode("a", {
                            href: props.subheadLink,
                            "data-ga-category": "Homepage",
                            "data-ga-action": "trending_in_class View more videos",
                            "data-ga4-event-name": "homepage",
                            "data-ga4-action": "trending_in_class View more videos"
                        }, _toDisplayString(props.t.subheadLinkText), 9, _hoisted_5)
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(trendingList, (trending, key) => {
                        return (_openBlock(), _createElementBlock("div", {
                            id: "trending-block-item",
                            key: key,
                            class: "trending-block-column"
                        }, [
                            _createVNode(Modal, {
                                show: trending.show,
                                mode: "youtube",
                                "no-padding": true,
                                onClosed: () => trending.show = false
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("iframe", {
                                        width: "100%",
                                        height: "100%",
                                        src: `//www.youtube.com/embed/${trending.youtubeId}?rel=0`,
                                        frameborder: "0",
                                        allowfullscreen: ""
                                    }, null, 8, _hoisted_7)
                                ]),
                                _: 2
                            }, 1032, ["show", "onClosed"]),
                            _createElementVNode("a", {
                                style: _normalizeStyle({ backgroundImage: `url(${trending.backgroundImageLink})` }),
                                "data-ga-category": "Homepage",
                                "data-ga-action": trending.gaAction,
                                "data-ga4-event-name-category": "homepage",
                                "data-ga4-action": trending.gaAction,
                                "aria-live": trending.youtubeId ? 'polite' : undefined,
                                tabindex: trending.youtubeId ? '0' : undefined,
                                "aria-label": trending.youtubeId ? 'play video' : undefined,
                                title: trending.title,
                                onClick: _withModifiers(() => onBlockClick(trending), ["prevent"])
                            }, [
                                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "play" }, null, -1)),
                                _createElementVNode("span", _hoisted_9, [
                                    _createTextVNode(_toDisplayString(trending.title) + " ", 1),
                                    (trending.subtext.length > 0)
                                        ? (_openBlock(), _createElementBlock("em", _hoisted_10, _toDisplayString(trending.subtext), 1))
                                        : _createCommentVNode("", true)
                                ])
                            ], 12, _hoisted_8)
                        ]));
                    }), 128))
                ]),
                _renderSlot(_ctx.$slots, "default")
            ]));
        };
    }
});
